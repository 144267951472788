import React, { useEffect, useState } from "react";
import {
  Grow,
  Grid,
  Container,
  TextField,
  Button,
  Paper,
  AppBar,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { getPostsBySearch } from "../../actions/posts";
import Form from "../Form/Form";
import Posts from "../Posts/Posts";
// to use the styles will call useStyles and assign it to classes.
import useStyles from "./styles";
import TagsInput from "../TagsInput/TagsInput";
import Paginate from "../Paginate";
import MessageModel from "../Model/MessageModel";

// setup the URL search Params by using the current location as following
// after set it we can use it as a hook. and call it inside the home.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Home = () => {
  // Normal way to handle the update of current Id to update any post data and doing up to the App because
  // we need to share that with both posts & form.
  // Updated using redux
  // const [currentId, setCurrentId] = useState(0);
  // tags input.
  const [tags, setTags] = useState([]);
  const [search, setSearch] = useState("");

  // state for external markdown checker.
  // Managed by redux.
  // const [text, setText] = useState("");
  const [IsOpen, setIsOpen] = useState(false);

  const { postId } = useSelector((state) => state.postId);

  const classes = useStyles();

  // will dispatch an action by useEffect and import that action from action folder.
  const dispatch = useDispatch();
  // using the query function.
  const query = useQuery();
  const navigate = useNavigate();
  // so we can now using query read the URL and get all query like
  const searchQuery = query.get("searchQuery");
  // Get the page
  const page = query.get("page");
  // console.log(searchQuery);

  // useEffect(() => {
  //   dispatch(getPosts());
  //   // in dependencies we adding currentId to make all post dispatched again once update any of them.
  // }, [currentId, dispatch]);

  useEffect(() => {
    if (!searchQuery) {
      navigate("/posts?page=1");
    }
  }, []);

  const searchPost = () => {
    if (search.trim() || tags) {
      // Dispatch and action to fetch the search posts. ---> create one
      dispatch(getPostsBySearch({ search, tags: tags.join(",") }));
      navigate(
        `/posts/search?searchQuery=${search || "none"}&tags=${
          tags.join(",") || "none"
        }`
      );
    } else {
      navigate("/");
    }
  };

  // Search for post once click enter while typing.
  const handleKeyPress = (e) => {
    // incase of press enter key on the keyboard.
    if (e.keyCode === 13) {
      // want to perform search like press the btn.
      searchPost();
    }
  };

  // Func. to reset the search inputs and tags.
  const resetResult = () => {
    setTags([]);
    setSearch("");
    navigate("/posts?page=1");
  };

  return (
    //   {/* using grow component which provide some animation like grow in */}
    <div style={{ position: "relative" }}>
      {IsOpen && (
        <div
          className="modelDiv"
          style={{
            position: "absolute",
            width: "100%",
            zIndex: "1000",
            marginInline: "auto",
          }}
        >
          <MessageModel setIsOpen={setIsOpen} />
        </div>
      )}
      <Grow in>
        <Container maxWidth="xl" style={{ marginBottom: "30px" }}>
          {/* grid container will be the parent */}
          <Grid
            className={classes.mainContainer}
            container
            justifyContent="space-between"
            alignItems="stretch"
            spacing={3}
          >
            {/* grid item will be child */}
            <Grid item xs={12} sm={12} md={8}>
              <Posts />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <AppBar
                className={classes.appBarSearch}
                position="static"
                color="inherit"
                elevation={3}
              >
                <TextField
                  name="search"
                  variant="outlined"
                  label="Search by Title"
                  onKeyPress={handleKeyPress}
                  fullWidth
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <TagsInput setTags={setTags} tags={tags} />
                <Button
                  onClick={searchPost}
                  color="primary"
                  variant="contained"
                >
                  Search
                </Button>
                <Button
                  onClick={resetResult}
                  color="secondary"
                  size="small"
                  variant="contained"
                  style={{ marginTop: "0.5rem" }}
                >
                  Reset
                </Button>
              </AppBar>
              <Form currentId={postId} setIsOpen={setIsOpen} IsOpen={IsOpen} />
              {/* Pagination start */}
              <Paper elevation={3} className={classes.paginate}>
                <Paginate page={page} />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Grow>
    </div>
  );
};

export default Home;
