import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "newproject-dcf5f.firebaseapp.com",
  projectId: "newproject-dcf5f",
  storageBucket: "newproject-dcf5f.appspot.com",
  messagingSenderId: process.env.REACT_APP_MESSAGE_ID,
  appId: process.env.REACT_APP_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// initialize the auth process.
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, provider };

/* 
// The signed-in user info.
const user = result.user;
// This gives you a Facebook Access Token.
const credential = provider.credentialFromResult(auth, result);
const token = credential.accessToken;

*/
