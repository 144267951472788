import React, { useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Typography,
  ButtonBase,
  Avatar,
} from "@material-ui/core";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
// import ReactMarkdown from "react-markdown";
// import remarkGfm from "remark-gfm";

import useStyles from "./styles";
import { deletePost, likePost } from "../../../actions/posts";
import { setPostId } from "../../../actions/postId";

// {creator, title, message, tags, selectedFile}

const Post = ({ post }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // get the user from local-storage
  const user = JSON.parse(localStorage.getItem("profile"));
  let token;
  if (user?.token) {
    token = jwt_decode(user?.token);
  }

  // to accelerate the like process and not depending it on the async process.
  const [likes, setLikes] = useState(post?.likes);
  const userId = token?.sub || user?.result?._id;

  // function to handle likes update.
  const handleLike = async () => {
    dispatch(likePost(post._id));

    if (post.likes.find((like) => like === userId)) {
      setLikes(post.likes.filter((id) => id !== userId));
    } else {
      setLikes([...post.likes, userId]);
    }
  };

  // subComponent to handle the like or likes logic.
  const Likes = () => {
    if (likes.length > 0) {
      return likes.find((like) => like === userId) ? (
        <>
          <ThumbUpAltIcon fontSize="small" />
          &nbsp;
          {likes.length > 2
            ? `You and ${likes.length - 1} others`
            : `${likes.length} like${likes.length > 1 ? "s" : ""}`}
        </>
      ) : (
        <>
          <ThumbUpAltOutlinedIcon fontSize="small" />
          &nbsp;{likes.length} {likes.length === 1 ? "Like" : "Likes"}
        </>
      );
    }

    return (
      <>
        <ThumbUpAltOutlinedIcon fontSize="small" />
        &nbsp;Like
      </>
    );
  };

  // disable like btn if there is no user login
  let isLogIn = true;
  if (!user?.result) {
    if (!user?.user) {
      isLogIn = false;
    }
  }
  // Function to route for every single post.
  const openPost = () => {
    navigate(`/posts/${post._id}`);
  };

  // trim the text message to have more control over the card size.
  // let trimedPostMessage;
  // if (post) {
  //   if (post.message.length > 75) {
  //     trimedPostMessage = `${post.message.substring(0, 75)}...`;
  //   } else {
  //     trimedPostMessage = post.message;
  //   }
  // }

  return (
    <Card raised elevation={5} className={classes.card}>
      <ButtonBase className={classes.cardAction} onClick={openPost}>
        <CardContent className={classes.cardContentWarper}>
          {/* <Typography variant='body1' color='textSecondary' component="p">{trimedPostMessage}</Typography> */}

          {/* <ReactMarkdown children={trimedPostMessage} remarkPlugins={[remarkGfm]} /> */}
          <Avatar className={classes.purple} alt={post.name}>
            {post?.name?.charAt(0).toUpperCase()}
          </Avatar>
          <div style={{ marginLeft: "15px" }}>
            <Typography variant="subtitle1">
              {post?.name?.toUpperCase()}
            </Typography>
            <Typography variant="caption">
              {moment(post.createdAt).fromNow()}
            </Typography>
          </div>
        </CardContent>

        <CardMedia
          className={classes.media}
          image={post.selectedFile}
          title={post.title}
        />

        {/* <div className={classes.overlay}>
          change the post.creator to post.name
          <Typography variant='h6'>{post.name}</Typography>
          <Typography variant='body2'>{moment(post.createdAt).fromNow()}</Typography>
        </div> */}

        {/* only show for the user post owner */}
        {(token?.sub === post?.creator ||
          user?.result?._id === post?.creator) && (
          <div className={classes.overlay2}>
            {/* this btn will be the responsible for getting the id for any post. */}
            <div
              className={classes.horizBtn}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                // setCurrentId(post._id);
                dispatch(setPostId(post._id));
              }}
            >
              <MoreHorizIcon fontSize="medium" />
            </div>
          </div>
        )}

        <div className={classes.details}>
          <Typography variant="body2" color="textSecondary">
            {post.tags.map((tag) => `#${tag} `)}
          </Typography>
        </div>

        <Typography className={classes.title} variant="h5" gutterBottom>
          {post.title}
        </Typography>
      </ButtonBase>

      <CardActions className={classes.cardActions}>
        <Button
          size="medium"
          color="primary"
          disabled={!isLogIn}
          onClick={handleLike}
        >
          {/* <ThumbUpAltIcon className={classes.thumbUp} fontSize="small" /> */}
          {/* Like */}
          {/* {post.likeCount} */}
          <Likes />
        </Button>
        {/* need to show the delete only for the login user post of his own */}
        {(token?.sub === post?.creator ||
          user?.result?._id === post?.creator) && (
          <Button
            size="small"
            color="primary"
            onClick={() => dispatch(deletePost(post._id))}
          >
            <DeleteIcon fontSize="small" />
            Delete
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default Post;
