import React from "react";
import {
  Grid,
  CircularProgress,
  Paper,
  Button,
  Typography,
} from "@material-ui/core";
// to get access on the redux Global store.
// useSelector -> receive callback func. which have access to the global state then you get any reducer you required.
import { useSelector } from "react-redux";

import Post from "./Post/Post";
import useStyles from "./styles";
import { Link } from "react-router-dom";

const Posts = () => {
  const { posts, isLoading } = useSelector((state) => state.posts);
  const classes = useStyles();
  // const user = JSON.parse(localStorage.getItem('profile'));

  // useEffect(() => {

  // }, [posts, user]);

  // console.log(posts);

  if (!posts.length && !isLoading)
    return (
      <Paper elevation={3} style={{ textAlign: "center", padding: "10px 0 " }}>
        <Typography variant="h4"> No Post...</Typography>
        <Typography variant="subtitle1"> Please Try Again Later.</Typography>
        <Button
          component={Link}
          to="/"
          variant="outlined"
          color="primary"
          style={{ marginTop: "10px" }}
        >
          Go Home
        </Button>
      </Paper>
    );

  return isLoading ? (
    <CircularProgress />
  ) : (
    <Grid
      className={classes.mainContainer}
      container
      alignItems="stretch"
      spacing={3}
    >
      {posts.map((post) => (
        <Grid key={post._id} item xs={12} sm={6} lg={4}>
          <Post post={post} />
        </Grid>
      ))}
    </Grid>
  );
};

export default Posts;
