const MsgTextReducer = (state = { MsgText: "" }, action) => {
  switch (action.type) {
    case "ADD_TXT_MSG":
      return { MsgText: action.payload };

    default:
      return state;
  }
};

export default MsgTextReducer;
