import { makeStyles } from "@material-ui/core/styles";
import { deepPurple } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
  appBar: {
    borderRadius: 15,
    margin: "30px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 50px",
    // backgroundColor: 'rgba(255,255,255,0.1)',
    backgroundColor: "transparent",
    backdropFilter: "blur(2px)",
    color: "black",
  },
  heading: {
    color: "#5BE1E6",
    textDecoration: "none",
    fontWeight: "600",
  },
  image: {
    marginLeft: "15px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
    width: "400px",
  },
  profile: {
    display: "flex",
    justifyContent: "space-between",
    width: "400px",
  },
  userName: {
    display: "flex",
    alignItems: "center",
  },
  brandContainer: {
    display: "flex",
    alignItems: "center",
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    border: "2px solid rgba(255, 255, 255, 1)",
  },
  [theme.breakpoints.down("sm")]: {
    appBar: {
      flexDirection: "column",
    },
    toolbar: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    profile: {
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "1rem 0 0.5rem",
    },
    logout: {
      marginTop: "1rem",
    },
    image: {
      marginLeft: "0",
    },
  },
}));

// import { makeStyles } from '@material-ui/core/styles';

// // now create the style by using makeStyles method which accept callback function and return an object.
// export default makeStyles((theme) => ({
//   appBar: {
//     borderRadius: 15,
//     margin: '30px 0',
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   heading: {
//     color: 'rgba(0,183,255, 1)',
//   },
//   image: {
//     marginLeft: '15px',
//   },
//   [theme.breakpoints.down('sm')]: {
//     heading: {
//       fontSize: '2rem',
//     },
//     image: {
//       height: '40px',
//       padding: '0.5rem 0',
//     },
//   }
// }));
