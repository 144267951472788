import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Container } from "@material-ui/core";

import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import Auth from "./components/Auth/Auth";
import { CircularProgress } from "@mui/material";
const PostDetails = React.lazy(() =>
  import("./components/PostDetails/PostDetails")
);

const App = () => {
  const user = JSON.parse(localStorage.getItem("profile"));

  return (
    <BrowserRouter>
      <Container maxWidth="xl">
        {/* will move the navbar to a separate component. */}
        <Navbar />
        <Suspense fallback={<CircularProgress />}>
          <Routes>
            <Route
              exact
              path="/auth"
              element={!user ? <Auth /> : <Navigate replace to="/posts" />}
            />
            <Route exact path="/" element={<Navigate replace to="/posts" />} />
            <Route exact path="/posts" element={<Home />} />
            {/* <Route exact path='/edit' element={<MessageModel />} /> */}
            {/* <Route exact path='/posts' element={<MessageModel />} /> */}
            <Route exact path="/posts/search" element={<Home />} />
            <Route exact path="/posts/:id" element={<PostDetails />} />
          </Routes>
        </Suspense>
      </Container>
    </BrowserRouter>
  );
};

export default App;
