import React, { useState } from "react";
import {
  Avatar,
  Button,
  Paper,
  Grid,
  Typography,
  Container,
  TextField,
} from "@material-ui/core";
// import { GoogleLogin } from 'react-google-login';
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { InputAdornment, IconButton } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import { auth, provider } from "../firebase/firebase";
import useStyles from "./styles";
import Icon from "./Icon";
import { signup, signin } from "../../actions/auth";

// Initial form data.
const initialFormState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const Auth = () => {
  // state to handle show password icon click.
  const [showPassword, setShowPassword] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  // state to handle the form data.
  const [formData, setFormData] = useState(initialFormState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const classes = useStyles();

  // test variable
  // let isSignup = false;

  // form submit function
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);
    // handle the both event for signup or sign in.
    if (isSignup) {
      dispatch(signup(formData, navigate));
    } else {
      dispatch(signin(formData, navigate));
    }
  };

  // handling the input change function.
  const handleChange = (e) => {
    // Get the data entered by the user and using the speared method.
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Function to show the password.
  const handleShowPassword = () => {
    // whenever we need to use the previous state we should use a call back func. to get it.
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const switchMode = () => {
    setIsSignup((prevIsSignup) => !prevIsSignup);
    // reset the showPassword to avoid keep shown while transfer.
    setShowPassword(false);
  };

  // Functions to handle google login success (async) and failure.
  // const googleSuccess = async (response) => {
  //     const userProfile = response?.profileObj;
  //     const token = response?.tokenId;

  //     console.log(response);

  //     // Then we need to dispatch that data for the client.
  //     try {
  //         dispatch({type: 'AUTH', data: { userProfile, token }});

  //         // redirect the client to home page.
  //         navigate('/');

  //     } catch (error) {
  //         console.log("Error: ", error);
  //     }
  // }

  // const googleFailure = () => {
  //     alert("Google Sign In was Unsuccessful.");
  // }

  // function for signIn with Google.
  const signIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.idToken;
        // console.log(result);

        dispatch({ type: "AUTH", data: { user, token } });
        // console.log(result);
        // console.log(credential.idToken);
        navigate("/");
      })
      .catch((err) => {
        console.log(err.message);
        alert("Google login does not work properly please try again later.");
      });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.paper} elevation={3}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h5">{isSignup ? "Sign Up" : "Sign In"}</Typography>
        <form
          autoComplete="off"
          className={classes.form}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            {/* check if client sign up show the first and last name we can create dynamic input. */}
            {isSignup && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="firstName"
                    label="First Name"
                    onChange={handleChange}
                    autoFocus
                    variant="outlined"
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="lastName"
                    label="Last Name"
                    onChange={handleChange}
                    variant="outlined"
                    required
                    fullWidth
                  />
                </Grid>
                {/* <Input name='firstName' label="First Name" onChange={handleChange} autoFocus half />
                                <Input name='lastName' label="Last Name" onChange={handleChange} half /> */}
              </>
            )}
            <Grid item xs={12}>
              <TextField
                name="email"
                label="Email Address"
                onChange={handleChange}
                variant="outlined"
                type="email"
                required
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="password"
                label="Password"
                onChange={handleChange}
                variant="outlined"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleShowPassword}>
                        {!showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
                fullWidth
              />
            </Grid>
            {/* <Input name='email' label="Email Address" onChange={handleChange} type="email" />
                    <Input name='password' label="Password" onChange={handleChange} type={showPassword ? "text" : "password"} handleShowPassword={handleShowPassword} /> */}
            {/* {isSignup && <Input name='confirmPassword' label="Repeat Password" onChange={handleChange} type="password" />} */}
            {isSignup && (
              <Grid item xs={12}>
                <TextField
                  name="confirmPassword"
                  label="Repeat Password"
                  onChange={handleChange}
                  variant="outlined"
                  type="password"
                  required
                  fullWidth
                />
              </Grid>
            )}
          </Grid>
          {/* Normal login or register setup */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {isSignup ? "Sign Up" : "Sign In"}
          </Button>

          {/* Google login setup */}
          <Button
            className={classes.googleButton}
            color="primary"
            fullWidth
            onClick={signIn}
            startIcon={<Icon />}
            variant="contained"
          >
            Google Sign In
          </Button>
          {/* <GoogleLogin 
                    clientId='246188046388-mhv31jfl22r3sqdtmv3kd1l8qfsj4k2t.apps.googleusercontent.com'
                    render={(renderProps) => (
                        <Button 
                            className={classes.googleButton} 
                            color='primary' 
                            fullWidth 
                            onClick={renderProps.onClick} 
                            disabled={renderProps.disabled} 
                            startIcon={<Icon />}
                            variant="contained" 
                        >
                            Google Sign In
                        </Button>
                    )}

                    onSuccess={googleSuccess}
                    onFailure={googleFailure}
                    cookiePolicy='single_host_origin'
                /> */}
          {/* btn to control toggle between sign In OR Up */}
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button onClick={switchMode}>
                {isSignup ? (
                  <>
                    <small>Already have an account?</small>
                    <b> Sign In</b>
                  </>
                ) : (
                  <>
                    <small>Don't have an account? </small>
                    <b>Sign Up</b>
                  </>
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default Auth;
