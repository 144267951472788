// constant variables to be used inside Actions which will be more secure from typo. mistakes.
// which will save lots of time debugging to find the typo because if you have typo there is no error appear.
// Also you can use inside the reducer itself.
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
export const FETCH_ALL = "FETCH_ALL";
export const FETCH_POST = "FETCH_POST";
export const FETCH_SEARCHED_POSTS = "FETCH_SEARCHED_POSTS";
export const CREATE = "CREATE";
export const LIKE = "LIKE";
export const COMMENTS = "COMMENTS";

// Loading constant
export const START_LOADING = "START_LOADING";
export const END_LOADING = "END_LOADING";

// Auth constant
export const AUTH = "AUTH";
export const LOGOUT = "LOGOUT";

