import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppBar, Avatar, Button, Toolbar, Typography } from "@material-ui/core";
import decode from "jwt-decode";
import { signOut } from "firebase/auth";

import { auth } from "../firebase/firebase";
import logo from "../../images/logo.png";

import useStyles from "./styles";

const Navbar = () => {
  const classes = useStyles();
  // IN this useState we need to retrieve the user data immediately from the local-storage.
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // console.log(user);

  // Logout the user.
  const logout = () => {
    signOut(auth)
      .then(() => {
        // Sign-out successful.
        console.log("User Logout successfully");
      })
      .catch((error) => {
        // An error happened.
        console.log(error.message);
      });
    // need to dispatch an action.
    dispatch({ type: "LOGOUT" });
    setUser(null);
    window.location.reload(true);
    navigate("/");
  };

  // redirect the client to the home page.
  useEffect(() => {
    const token = user?.token;

    // jwt check if it expire to log out the user if so.
    if (token) {
      const decodedToken = decode(token);
      if (decodedToken.exp * 1000 < new Date().getTime()) logout();
    }

    setUser(JSON.parse(localStorage.getItem("profile")));
  }, [location, user?.token]);

  // const user = null;
  return (
    <AppBar className={classes.appBar} position="static">
      <div className={classes.brandContainer}>
        <Link to="/" title="Home Page">
          <img className={classes.image} src={logo} alt="memories" width="70" />
        </Link>
        <Typography
          component={Link}
          to="/"
          className={classes.heading}
          variant="h4"
          align="center"
        >
          <span style={{ color: "black" }}>Tech</span>Blog
        </Typography>
      </div>
      {/* User Data */}
      <Toolbar className={classes.toolbar}>
        {user ? (
          <div className={classes.profile}>
            <Avatar
              className={classes.purple}
              alt={user.user?.displayName || user.result.name}
              src={user?.user?.photoURL}
            >
              {user?.user?.displayName.charAt(0).toUpperCase() ||
                user.result.name.charAt(0).toUpperCase()}
            </Avatar>
            <Typography className={classes.userName} variant="h6">
              {user?.user?.displayName || user.result.name}
            </Typography>
            <Button
              variant="contained"
              className={classes.logout}
              color="secondary"
              onClick={logout}
            >
              Logout
            </Button>
          </div>
        ) : (
          <Button
            component={Link}
            to="/auth"
            variant="contained"
            color="primary"
          >
            Sign In
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
