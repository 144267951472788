// Import all constant for string replacement.
import {
  FETCH_ALL,
  FETCH_SEARCHED_POSTS,
  CREATE,
  UPDATE,
  DELETE,
  LIKE,
  START_LOADING,
  END_LOADING,
  FETCH_POST,
  COMMENTS,
} from "../constants/actionTypes";
// first will import the api files which will be used along with the action.
import * as api from "../api";

// Create an action by using action Creators {which is Functions that return an action}
// where the action is an object contains the type == name & payload == data.
// because we usually fetching data which will take time so the redux-thunk comes to play important role here.
// the syntax for using thunk(to use the async way) => adding another function -> async (dispatch)
// And instead of returning the action itself we dispatch the action like below.

// const getPosts = () => async (dispatch) => {
//     const action = {
//         type: 'FETCH_ALL',
//         payload: []
//     }

//     // return action;
//     dispatch(action);
// }

export const getPost = (id) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    // console.log(searchQuery)
    const { data } = await api.fetchPost(id);
    dispatch({ type: FETCH_POST, payload: data });

    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(`ERROR: ${error}`);
  }
};

// To use the above format for getting the data will do the following.
// after adding the pagination we need more control over the number of posts and pages.
export const getPosts = (page) => async (dispatch) => {
  try {
    // Adding the start and end loading state before calling the api and after fetching the data.
    dispatch({ type: START_LOADING });
    // fetching the data.
    const { data } = await api.fetchPosts(page);
    // dispatch the action.
    // Note after update the backend with pagination pages now the data object contains more than normal posts.
    // console.log(data);
    dispatch({ type: FETCH_ALL, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(`ERROR: ${error}`);
  }
};

// action creator to get all post requested from the search params.
export const getPostsBySearch = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    // console.log(searchQuery)
    const {
      data: { data },
    } = await api.fetchPostsBySearch(searchQuery);
    // console.log(data);
    dispatch({ type: FETCH_SEARCHED_POSTS, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(`ERROR: ${error}`);
  }
};

// create action creator function to create new post.
export const createPost = (post) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.createPost(post);

    dispatch({ type: CREATE, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(`ERROR: ${error}`);
  }
};

// Create an action creator for updating the post data.
export const updatePost = (id, post) => async (dispatch) => {
  try {
    const { data } = await api.updatePost(id, post);
    // console.log("data: ", data);
    dispatch({ type: UPDATE, payload: data });
  } catch (error) {
    console.log(`ERROR: ${error}`);
  }
};

// Create an action creator for deleting the post data.
export const deletePost = (id) => async (dispatch) => {
  try {
    // because we are not waiting for data to be received no need to assign to variable.
    await api.deletePost(id);

    dispatch({ type: DELETE, payload: id });
  } catch (error) {
    console.log(`ERROR: ${error}`);
  }
};

// Action function creator to increment the likes for posts.

export const likePost = (id) => async (dispatch) => {
  try {
    const { data } = await api.likePost(id);

    dispatch({ type: LIKE, payload: data });
  } catch (error) {
    console.log(`ERROR: ${error}`);
  }
};

// Add comment to a post.
export const commentPost = (comment, id) => async (dispatch) => {
  try {
    const { data } = await api.comment(comment, id);

    dispatch({ type: COMMENTS, payload: data });
    return data.comments;
  } catch (error) {
    console.log(`COMMENT ERROR: ${error}`);
  }
};
