import { makeStyles } from '@material-ui/core/styles';

// now create the style by using makeStyles method which accept callback function and return an object.
export default makeStyles((theme) => ({
  // modelDiv: {
  //   position: 'absolute', 
  //   width: 'calc(100% - 48px)', 
  //   zIndex: '1000',
  //   marginInline: 'auto',
  // },
  appBarSearch: {
    borderRadius: 4,
    marginBottom: '1rem',
    display: 'flex',
    padding: '16px',
  },
  paginate: {
    marginTop: '1rem',
    padding: '1rem 0'
  },
  [theme.breakpoints.down('sm')]: {
    mainContainer: {
      flexDirection: 'column-reverse',
    },
  },
  model: {
    position: 'absolute',
  }
}));