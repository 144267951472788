import { combineReducers } from "redux";

import posts from "./posts";
import auth from "./auth";
import postId from "./post";
import msgTxt from "./messageText";

export default combineReducers({
  posts,
  auth,
  postId,
  msgTxt,
});
