// Import all constant for string replacement.
import { AUTH } from '../constants/actionTypes';
// first will import the api files which will be used along with the action.
import * as api from '../api';


// action creator function to handle the signup action
export const signup = (formData, navigate) => async (dispatch) => {
    try {
        // register the new user ==> link it to the database.
        // Once reach to this point we need to go to the backend and register the endpoint 
        // which will be called here to post the data.
        const { data } = await api.signUp(formData);

        // dispatch action to the reducer.
        dispatch({ type: AUTH, data });

        // transfer the client to the home page.
        navigate('/');
    } catch (error) {
        console.log(`ERROR: ${error}`);
    }

}


// action creator function to handle the sign in action
export const signin = (formData, navigate) => async (dispatch) => {
    
    try {
        // login the new user ==> link it to the database.
        const { data } = await api.signIn(formData);

        // dispatch action to the reducer.
        dispatch({ type: AUTH, data });

        // transfer the client to the home page.
        navigate('/');
    } catch (error) {
        console.log(`ERROR: ${error}`);
    }

}