// import axios to use it for making api calls.
import axios from "axios";

// create axios instance by using the baseURL to manage interact with different routes not only posts.
// const API = axios.create({ baseURL: "http://localhost:5000" }); // for testing
const API = axios.create({ baseURL: process.env.REACT_APP_BASE_URL });
// const profile = JSON.parse(localStorage.getItem('profile'));

// Adding setting to pass the token to the backend to verify the user.
API.interceptors.request.use((req) => {
  if (localStorage.getItem("profile")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("profile")).token
    }`;
  }

  return req;
});

// specify the url which will be the link to the backend. localhost only for testing.
// const url = 'http://localhost:5000/posts';
// const url = 'https://memories-keeper.herokuapp.com/posts';

export const fetchPosts = (page) => API.get(`/posts?page=${page}`);
// now will focus on redux capabilities where all actions will be done toward the backend by using redux.
// fetching single post
export const fetchPost = (id) => API.get(`/posts/${id}`);

// Fetch posts by search.
export const fetchPostsBySearch = (SearchQuery) =>
  API.get(
    `/posts/search?SearchQuery=${SearchQuery.search || ""}&tags=${
      SearchQuery.tags
    }`
  );

// post request for new post.
export const createPost = (newPost) => API.post("/posts", newPost);

// make new request for updating the post data by id.
export const updatePost = (id, postData) => API.patch(`/posts/${id}`, postData);

// after setting any new route in the backend we can initiate now request to it.
// Deleting request.
export const deletePost = (id) => API.delete(`/posts/${id}`);

// new Request to increment the like count.
export const likePost = (id) => API.patch(`/posts/${id}/likePost`);

// Adding new comment to post.
export const comment = (comment, id) =>
  API.post(`/posts/${id}/commentPost`, { comment });

// signIn & signup api.
export const signIn = (formData) => API.post("/user/signin", formData);
export const signUp = (formData) => API.post("/user/signup", formData);
