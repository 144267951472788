// Import all constant for string replacement.
import {
  FETCH_ALL,
  FETCH_SEARCHED_POSTS,
  CREATE,
  UPDATE,
  DELETE,
  LIKE,
  START_LOADING,
  END_LOADING,
  FETCH_POST,
  COMMENTS,
} from "../constants/actionTypes";

// reducer is a function that accept two parameters first will be the state and second will be an action.
// while we are working with posts so will recall the state === posts here. NOTE to give state initial value.
// instead of create const reducer = and then export it will do that direct.

const reducer = (state = { isLoading: true, posts: [] }, action) => {
  switch (action.type) {
    case START_LOADING:
      // Handle loading state.
      return { ...state, isLoading: true };

    case END_LOADING:
      return { ...state, isLoading: false };

    case DELETE:
      return {
        ...state,
        posts: state.posts.filter((post) => post._id !== action.payload),
      };

    case UPDATE:
      // console.log(action.payload); Object.assign(post, action.payload)
      return {
        ...state,
        posts: state.posts.map((post) =>
          post._id === action.payload._id ? action.payload : post
        ),
      };

    case LIKE:
      // console.log(action.payload);
      return {
        ...state,
        posts: state.posts.map((post) =>
          post._id === action.payload._id
            ? Object.assign(post, action.payload)
            : post
        ),
      };

    case COMMENTS:
      return {
        ...state,
        posts: state.posts.map((post) => {
          // Update only the post which we add comment to it.
          if (post._id === action.payload._id) {
            return action.payload;
          }
          // Here we return all other post normally without any change.
          return post;
        }),
      };

    case FETCH_ALL:
      // after adding the pagination we need to return an object
      return {
        ...state,
        posts: action.payload.data,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
      };

    case FETCH_POST:
      return { ...state, post: action.payload };

    case FETCH_SEARCHED_POSTS:
      return {
        ...state,
        posts: action.payload,
      };

    case CREATE:
      return { ...state, posts: [action.payload, ...state.posts] };

    default:
      return state;
  }
};

export default reducer;
