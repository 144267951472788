import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import remarkGfm from "remark-gfm";
import emoji from "remark-emoji";
import { atomOneDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

import "./MessageModel.css";
import { Button } from "@mui/material";
import { setMsgTxt } from "../../actions/messageText";

const MessageModel = ({ setIsOpen }) => {
  const [textValue, setTextValue] = useState("");
  const dispatch = useDispatch();

  const save = () => {
    dispatch(setMsgTxt(textValue));
    setIsOpen(false);
  };

  return (
    <div className="parentEditor">
      <div className="saveBtn">
        <textarea
          className="textArea"
          placeholder="Write your text"
          autoFocus
          value={textValue}
          onChange={(e) => setTextValue(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={save}
          fullWidth
          style={{ marginTop: "0.5rem" }}
        >
          Save
        </Button>
      </div>
      <ReactMarkdown
        className="markdown"
        children={textValue}
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || "");
            return !inline && match ? (
              <SyntaxHighlighter
                children={String(children).replace(/\n$/, "")}
                style={atomOneDark}
                language={match[1]}
                PreTag="div"
                {...props}
              />
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
        }}
        remarkPlugins={[
          remarkGfm,
          [
            emoji,
            {
              padSpaceAfter: false,
              emoticon: true,
            },
          ],
        ]}
      />
    </div>
  );
};

export default MessageModel;
