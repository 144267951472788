const PostReducer = (state = { postId: "" }, action) => {
  switch (action.type) {
    case "ADD_POST_ID":
      return { postId: action.payload };

    default:
      return state;
  }
};

export default PostReducer;
