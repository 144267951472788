import React from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import "./TagsInput.css";

const TagsInput = ({ setTags, tags }) => {
  // Func. to remove tag from the list
  const removeTags = (indexToRemove) => {
    setTags([...tags.filter((_, index) => index !== indexToRemove)]);
  };
  // Func. to add tag to the list.
  const addTags = (event) => {
    if (event.target.value !== "") {
      setTags([...tags, event.target.value.toLowerCase()]);
      event.target.value = "";
    }
  };

  return (
    <div className="tags-input">
      <ul id="tags">
        {tags.map((tag, index) => (
          <li key={index} className="tag">
            <span className="tag-title">{tag}</span>
            <span className="tag-close-icon" onClick={() => removeTags(index)}>
              <HighlightOffIcon color="secondary" />
            </span>
          </li>
        ))}
      </ul>
      <input
        type="text"
        onKeyUp={(event) => (event.key === "Enter" ? addTags(event) : null)}
        // placeholder="Press enter to add tags"
        placeholder="&nbsp;"
      />
      <p className="placeHolder_txt">Search By Tags</p>
    </div>

    // <fieldset className="tags-input">
    //   <legend>Search By Tags</legend>
    //   <ul id="tags">
    //     {tags.map((tag, index) => (
    //       <li key={index} className="tag">
    //         <span className="tag-title">{tag}</span>
    //         <span className="tag-close-icon" onClick={() => removeTags(index)}>
    //           <HighlightOffIcon color="secondary" />
    //         </span>
    //       </li>
    //     ))}
    //   </ul>
    //   <input
    //     type="text"
    //     onKeyUp={(event) => (event.key === "Enter" ? addTags(event) : null)}
    //     placeholder="Press enter to add tags"
    //   />
    // </fieldset>
  );
};

export default TagsInput;

// const App = () => {
// 	const selectedTags = tags => {
// 		console.log(tags);
// 	};
// 	return (
// 		<div className="App">
// 			<TagsInput selectedTags={selectedTags}  tags={['Nodejs', 'MongoDB']}/>
// 		</div>
// 	);
// };
