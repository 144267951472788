import React, { useEffect, useState } from "react";
import { TextField, Button, Typography, Paper } from "@material-ui/core";
import FileBase from "react-file-base64";
import { useDispatch, useSelector } from "react-redux";

import useStyles from "./styles";
import { createPost, updatePost } from "../../actions/posts";
import { setMsgTxt } from "../../actions/messageText";
import { setPostId } from "../../actions/postId";

const Form = ({ currentId, setIsOpen, IsOpen }) => {
  const [postData, setPostData] = useState({
    // creator: '',
    title: "",
    message: "",
    tags: "",
    selectedFile: "",
  });

  // console.log(text);

  const classes = useStyles();
  // to dispatch the action of adding new post. along with createPost action function.
  const dispatch = useDispatch();
  const { MsgText } = useSelector((state) => state.msgTxt);

  // Fetching the posts from the reducer incase of update post.
  const currentPost = useSelector((state) =>
    currentId ? state.posts.posts.find((p) => p._id === currentId) : null
  );
  // get the user from local-storage
  const user = JSON.parse(localStorage.getItem("profile"));

  useEffect(() => {
    if (currentPost) setPostData(currentPost);
  }, [currentPost]);

  // Function to handle the basic check for the form.
  const checkForm = () => {
    if (user) {
      if (
        postData.message.trim().length > 0 &&
        postData.title.trim().length > 0 &&
        postData.tags.length > 0 &&
        postData.selectedFile.trim().length > 0
      ) {
        console.log("Done");
        return true;
      } else {
        alert(
          "Please fill all inputs with required data to proceed your post."
        );
        return false;
      }
    } else {
      return false;
    }
  };

  // clear the post form.
  const clear = () => {
    // setCurrentId(0);
    // setText("");
    dispatch(setPostId(""));
    dispatch(setMsgTxt(""));
    setPostData({
      // creator: '',
      title: "",
      message: "",
      tags: "",
      selectedFile: "",
    });
  };

  // Handle adding the additional text from the model if existing.
  useEffect(() => {
    if (IsOpen === false) {
      if (MsgText !== "") {
        setPostData({
          ...postData,
          message: `${postData.message} \n ${MsgText}`,
        });
      }
    }
  }, [IsOpen]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // now need tp check if there is any currentId or not.
    if (checkForm()) {
      if (!currentId) {
        dispatch(
          createPost({
            ...postData,
            name: user?.result?.name || user?.user?.displayName,
          })
        );
        clear();
      } else {
        dispatch(
          updatePost(currentId, {
            ...postData,
            name: user?.result?.name || user?.user?.displayName,
          })
        );
        clear();
      }
    } else {
      return console.log("Form not completed.");
    }
  };

  // show card if there is no login user to prevent from make new post till login./////////
  if (!user?.result?.name) {
    if (!user?.user?.displayName) {
      return (
        <Paper className={classes.paper} elevation={3}>
          <Typography variant="h6" align="center">
            Please Sign In to create your own memories and like other's
            memories.
          </Typography>
        </Paper>
      );
    }
  }

  const openBox = () => {
    setIsOpen(true);
  };

  return (
    <Paper className={classes.paper} elevation={3}>
      <form
        autoComplete="off"
        noValidate
        className={`${classes.root} ${classes.form}`}
        onSubmit={handleSubmit}
      >
        <Typography variant="h6">
          {currentId ? "Editing" : "Create"} Your Memories
        </Typography>
        {/* No need now for the creator field which is now handled by the backend */}
        {/* <TextField 
          name='creator' 
          variant='outlined' 
          label='Creator' 
          fullWidth
          value={postData.creator}
          onChange={(e) => setPostData({ ...postData, creator: e.target.value })} 
        /> */}
        <TextField
          name="title"
          variant="outlined"
          label="Title"
          fullWidth
          value={postData.title}
          onChange={(e) => setPostData({ ...postData, title: e.target.value })}
        />
        {/* <TextField 
          name='message' 
          variant='outlined' 
          label='Message' 
          fullWidth
          value={postData.message}
          onChange={(e) => setPostData({ ...postData, message: e.target.value })} 
        /> */}
        <TextField
          id="boxMessage"
          name="message"
          label="Message accept markdown syntax."
          variant="outlined"
          multiline
          fullWidth
          minRows={4}
          maxRows={6}
          value={postData.message}
          onChange={(e) =>
            setPostData({ ...postData, message: e.target.value })
          }
        />
        <span style={{ color: "red", fontWeight: "600", marginRight: "10px" }}>
          OR
        </span>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={openBox}
        >
          Open Message Model
        </Button>
        <TextField
          name="tags"
          variant="outlined"
          label="Tags"
          fullWidth
          value={postData.tags}
          onChange={(e) =>
            setPostData({ ...postData, tags: e.target.value.split(",") })
          }
        />
        <small style={{ fontSize: "12px", textAlign: "left", width: "95%" }}>
          Tags Comma Separated
        </small>
        <div className={classes.fileInput}>
          <FileBase
            type="file"
            multiple={false}
            onDone={({ base64 }) =>
              setPostData({ ...postData, selectedFile: base64 })
            }
          />
        </div>
        <Button
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          fullWidth
        >
          Submit
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={clear}
          fullWidth
          style={{ marginTop: "0.5rem" }}
        >
          Clear
        </Button>
      </form>
    </Paper>
  );
};

export default Form;
